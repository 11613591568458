import React, {useState, useEffect} from 'react';
import axios from "axios";
// React components
import Landing from '../../landing/Landing';
import ShowMore from '../../landing/ShowMore';
import WorkForm from './WorkForm';
import WorkEntry from './WorkEntry';
// Styling
import './work.css';
import '../../body/body.css';
// Assets
import LandingBackground from '../../../assets/landing-work.jpg'; 

/**
 * @brief Get a list of view components for available jobs
 * @param {*} workItems 
 * @param {*} handleSetJob 
 * @returns 
 */
function getAvailableWork(workItems, handleSetJob, language, text) {
    let availableWork = [];

    if (workItems == undefined || typeof(workItems) === 'string') return;
  
    for (let i = 0; i < workItems.length; i++) {
        // If we are on the english page - check whether swedish is a requirement -> if so, don't show it
        if (language === 'sv' || (language === 'en' && workItems[i].swedish_only !== 1)) {
            availableWork.push(<WorkEntry text={text} data={workItems[i]} onSetJob={handleSetJob} key={workItems[i].title} />)
        }
    }

    if (availableWork.length === 0) {
        return <h1 className='h3'> {text["jobs_no-jobs"]} </h1>
    }

    return availableWork;
}

const Work = ({ language, text, formText, onViewedPage }) => {
    onViewedPage('/work', 'Jobs');

    const [availableWork, setavailableWork] = useState([]);
    const [defaultJob, setDefaultJob] = useState('default');

    const handleSetJob = (job) => { 
        setDefaultJob(job); 
    };

    
    // Fetch all available work
    useEffect(() => {
        const backendUrl = process.env.REACT_APP_BACKEND_PHP;

        axios.get(backendUrl + 'WORK/') 
            .then(response => {
                setavailableWork(response.data);
            })
            .catch(error => {
                console.log(error);
            });
        }, []);

    const LandingContent = () => (
        <>
            <div className='work__landing'>
                <div className='work__landing-info'>
                    <h2>{text["jobs_info_title"]}</h2>
                    <p>{text["jobs_info_text"]}</p>
                    <p><span>{text["jobs_review-victor"]}</span> - Victor Hwasser</p>
                </div>
                <WorkForm language={language} text={formText} defaultJob={defaultJob} availableWork={availableWork} />
            </div>
            <ShowMore scrollTo={"work__available"} text={text['jobs_more']} />
        </>
    );

    return (
        <>
            <Landing language={language} content={LandingContent()} image={LandingBackground} />
            <div className='body__content'>
                
                <div className='work__availablework' id='work__available'>
                    <div className='work__landing-info-mobile'>
                        <h2>{text["jobs_info_title"]}</h2>
                        <p>{text["jobs_info_text"]}</p>
                    </div>
                    <h1> {text['jobs_available']} </h1>
                    <div className='work__availablework-content'>
                        {getAvailableWork(availableWork, handleSetJob, language, text)}
                    </div>
                    <br />
                    <br />
                    {(language === 'sv') && (
                        <p className='p1 center  work__search-by-mail'> {text["jobs_search-by-mail"]} </p>
                    )}
                    
                </div>
            </div>
        </>
    );
}

export default Work;