import React from 'react';
import { NavLink } from 'react-router-dom';
// Components
import Landing from '../../landing/Landing'
import ShowMore from '../../landing/ShowMore';
import scrollIntoView from '../../landing/scrollIntoView';
import ContactForm from './ContactForm';
import ContactPersonel from './ContactPersonel';
import ContactMap from './ContactMap';
import OfferForm from '../offer/OfferForm'
import {offerFormAll} from '../../form/formOfferArrends'
// Styling
import './contact.css';
import '../../form/form.css';
// Assets
import { BsEnvelope, BsTelephone } from "react-icons/bs";
import FormImage from '../../../assets/bogfelts-arbete.jpg'; 
import LandingBackground from '../../../assets/bogfelt-kontor.jpg'; 

var formBackground = {  
    backgroundImage: `url(${FormImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
};

const Contact = ({ language, text, formText, onViewedPage }) => {
    onViewedPage('/contact', 'Contact');

    const LandingContent = () => (
        <>
    
            <div className='contact__landing-main'>
                <div className='contact__landing-jour'>
                    <div className='contact__landing-component'>
                        <h1>{text['contact-direct-contact']}</h1>
                        <p>({text['contact-jour']})</p>
                        
                        <div className='contact__landing-icon-text'>
                            <BsTelephone color='white' size='32' /> 
                            <h2>08-732 57 13</h2>
                        </div>
                        <div className='contact__landing-icon-text'>
                            <BsEnvelope color='white' size='32' />
                            <h2>info@bogfelts.se</h2>
                        </div>
                    </div>
                </div>

                <div className='contact__landing-links'>
                    <div className='contact__landing-bottom-link'>
                        <h2>{text['contact-or-make-a']}</h2>
                        <NavLink className='button button_hollow-white' onClick={() => scrollIntoView("contact__form")} >
                            {text['contact-error-report'].toUpperCase()}
                        </NavLink>
                    </div>
                    <div className='contact__landing-bottom-link'>
                        <h2>{text['contact-services-text']}</h2>
                        <NavLink className='button button_hollow-white' onClick={() => scrollIntoView("contact__office")} >
                            {text['contact-services'].toUpperCase()}
                        </NavLink>
                    </div>
                    <div className='contact__landing-bottom-link'>
                        <h2>{text['contact-or-contact']}</h2>
                        <NavLink className='button button_hollow-white' onClick={() => scrollIntoView("contact__people")} >
                            {text['contact-employees'].toUpperCase()}
                        </NavLink>
                    </div>
                </div>
            </div>
            <ShowMore scrollTo={"contact_other"} text={text['contact-more']} />

        </>
    );

    return (
        <>
        <Landing content={LandingContent()} image={LandingBackground} />
        <div className='body__content' id='contact__office'>
            <div className='contact__component'>
                <h1 id='contact_other'>{text['contact-services']}</h1>
                <div className='contact__contact-info'>
                    <p className='p1'> {text["contact-form-description"]} </p>
                </div>
                <div className='contact__offer-form'>
                    <OfferForm language={language} text={formText} category={offerFormAll[language]} />
                </div>
            </div>
            <div className='body__content-empty-space' id='contact__form'></div>

            <div className='contact__component'>
                <div style={formBackground} className='contact__form-image'>
                    <div className='contact__form-overlay'>
                        <ContactForm language={language} text={formText} />
                    </div>
                </div>
            </div>
            <div className='body__content-empty-space' id='contact__people'></div>

            <div className='contact__component'>
                <h1>{text['contact-employees']}</h1>  
                <ContactPersonel language={language} />
            </div>
            <div className='body__content-empty-space' id='contact__visit'></div>

            <div className='contact__component'>
                <h1 className='no-bottom'>{text['contact-visit']}</h1>
                <ContactMap 
                    text={text}
                    url={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2030.7181168510303!2d18.033553977574883!3d59.404415274650795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f9d155d7e24db%3A0xebb54c074b34b832!2sBogfelts%20Street%20Lights%20AB!5e0!3m2!1ssv!2sse!4v1722241657143!5m2!1ssv!2sse"}
                    city="Stockholm"
                    street="Klockar Malms väg 23"
                    zip="182 34 Danderyd"
                />
                <div className='body__content-empty-space'></div>
                <ContactMap 
                    text={text}
                    url={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2039.430652146478!2d15.168524591428527!3d59.258953853342526!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465c14c87dc6c671%3A0x3f0a91f01a46b8e1!2sAdolfsbergsv%C3%A4gen%202-4%2C%20702%2027%20%C3%96rebro!5e0!3m2!1ssv!2sse!4v1722250365026!5m2!1ssv!2sse"}
                    city="Örebro"
                    street="Adolfsbergsvägen 2-4"
                    zip="702 27 Örebro"
                />
            </div>
            <div className='body__content-empty-space'></div>
        </div>
        </>
    )
}

export default Contact;