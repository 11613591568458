import React from 'react';
import { resetCookies } from '../../../functionality/cookies';
import Landing from '../../landing/Landing'
import "./policy.css";
// Assets
import LandingBackground from '../../../assets/landing-work.jpg'; 

const removeCookies = () => {
    resetCookies();
    window.location.reload();
}


const Policy = ({ text, onViewedPage }) => {
    onViewedPage('/policy', 'Policy');

    const LandingContent = () => (
        <>
            <div className='policy__content'>
                <h1> {text["policy-1-title"]} </h1>
                <p> {text["policy-1-text1"]} </p>
                <p> {text["policy-1-text2"]} </p>
        
                <h1> {text["policy-2-title"]} </h1>
                <p> {text["policy-2-text1"]} </p>

                <p> {text["policy-2-types"]} </p>
                <ol>
                    <li>1. {text["policy-2-types-1"]} </li>
                    <li>2. {text["policy-2-types-2"]} </li>
                    <li>3. {text["policy-2-types-3"]} </li>
                </ol>
                <p> {text["policy-google"]} <a style={{color: 'skyblue'}} href='https://policies.google.com/technologies/partner-sites' alt='google analytics info'> {text["policy-google-here"]}</a>. </p> 
        
                <h1> {text["policy-3-title"]} </h1>
                <p> {text["policy-3-text1"]} </p> 
                <p> {text["policy-3-text2"]} </p>
        
                <button className='button button_filled-black' onClick={() => removeCookies()}> 
                    {text["policy-withdraw"]} 
                </button>
            </div>
        </>
    );

    return <Landing content={LandingContent()} image={LandingBackground} />
}



export default Policy;