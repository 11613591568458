import { React, useState, useEffect } from 'react';
import api from '../api';
import { Button, Select, Modal } from "antd";
// React components
import AdminWorkAdd from './AdminWorkAdd';
import AdminWorkPut from './AdminWorkPut';
// Assets
import { ExclamationCircleFilled } from '@ant-design/icons';

/**
 * @brief Iterate JSON object with titles and generate data for work selection menu
 * @param {JSON} data 
 * @returns a list of json 
 */
function selectOptions(data) {
    const options = [];
    for (let i = 0; i < data.length; i++) {
        const jobTitle = {
            value: i,
            label: data[i].title
        };
        options.push(jobTitle);
    }

    return options;
}

const AdminWork = ({ onLogout }) => {
    // Handle work
    const [key, setKey] = useState(0); // To force re-draw modify screen
    const [workTitles, setWorkTitles] = useState([]);
    const [workData, setWorkData] = useState([]);
    const [selected, setSelected] = useState(0);
    // Choose selected title
    const handleChange = (value) => { 
        setSelected(value); 
        setKey(prevkey => prevkey + 1); 
    }; 
    // Used for confirming delete operation
    const { confirm } = Modal;
    // Either get a selected entry of a default empty entry
    function getCurrentWork() {
        if (workData.length == 0) return {id: 0, title: "", locations: "", requirements: "", merits: "", info: ""};
        else return workData[selected];
    }

    // Fetch all work titles
    useEffect(() => {
        const backendUrl = process.env.REACT_APP_BACKEND_PHP;

        // Get work titles and set them to selection menu
        api.get(backendUrl + 'WORK/') 
            .then(response => {
                const options = selectOptions(response.data);
                setWorkTitles(options);
                setWorkData(response.data)
            })
            .catch(error => {
                console.log(error);
            });
    }, []);

    const onUpdate = () => {}

    // Handle CRUD
    const [addOpen, setAddOpen] = useState(false);
    const [putOpen, setPutOpen] = useState(false);

    const [deleteOpen, setDeleteOpen] = useState(false);

    const showAdd = () => { setAddOpen(true); };
    const closeAdd = () => { setAddOpen(false); };

    const showPut = () => { setPutOpen(true); };
    const closePut = () => { setPutOpen(false); };

    /**
     * @brief Used for deleting a job
     * @returns 
     */
    const showConfirmDeletion = () => {
        if (workData.length === 0) return;

        confirm({
            title: `Vill du verkligen ta bort ${workData[selected].title}?`,
            icon: <ExclamationCircleFilled />,
            content: 'Obs! Åtgärden går inte att ångra!',
            cancelText: 'Avbryt',
            okText: 'Ja',
            okType: 'danger',
            onOk() {
                // Get news titles and set them to selection menu
                api.delete('WORK/' + workData[selected].id) 
                    .then(response => {
                        window.location.reload();
                    })
                    .catch(error => {
                        console.log(error);
                        onLogout();
                    });
            },
            onCancel() {
                
            },
        });
    };

    return (
        <>
        <h1>Lediga tjänster</h1>
        <Select
            defaultValue={0}
            style={{
                width: "50%",
                textAlign: "center",
            }}
            onChange={handleChange}
            options={workTitles}
        />
        <Button className='admin__main-button' onClick={showAdd}>Lägg till ny</Button>
        <AdminWorkAdd isModalOpen={addOpen} closeModal={closeAdd} onLogout={onLogout} />
        <Button className='admin__main-button' onClick={showPut}>Redigera</Button>
        <AdminWorkPut key={"work_put_form" + key} isModalOpen={putOpen} closeModal={closePut} onGetCurrentWork={getCurrentWork} onLogout={onLogout} />
        <Button className='admin__main-button' onClick={showConfirmDeletion}> Ta bort </Button>
        </>
    );
};

export default AdminWork;