
import React, { useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import { getCookie, setCookie } from '../../functionality/cookies';
import './consent.css';

const Consent = ({ text, onConsent }) => {
  const [open, setOpen] = useState((getCookie('cookie-accept') === '') ? true : false);

  const onClose = () => {
    setOpen(false);
  };

  const onAccept = () => {
    setCookie('cookie-accept', 'true', 360);
    onConsent();
    onClose();
  }

  const onReject = () => {
    setCookie('cookie-accept', 'false', 360);
    onClose();
  }

  return (
    <div className={'consent ' + (!open && 'consent__closed')}>
        <h1 className='h3 text-center'> {text['title']} </h1>
        <p className='p2 text-center half-width'> {text['text']} </p>
        <div className='consent__buttons'>
          <button className='consent_buttons-button b4' onClick={onAccept}> {text['button-accept']} </button>
          <button className='consent_buttons-button b4' onClick={onReject}> {text['button-reject']} </button>
          <HashLink className='consent_buttons-button2 b4' to='/policy' > {text['button-read']} </HashLink>
        </div>
    </div>
  );
};

export default Consent;