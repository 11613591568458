import React from 'react';
import './home-highlights.css';
import Trustable from '../../../assets/hl-trustable.jpg'; 
import Flexible from '../../../assets/hl-flexible.jpg'; 
import CustomerClose from '../../../assets/hl-customerclose.jpg'; 

// Define the component props
const HomeHighlights = ({ text }) => (
    <div className='home-highlight'>
      <h1> { text["highlights-title"] } </h1>
      <p className='p1 half-width center'> { text["highlights-text"] } </p>
      <div className='home-highlight__contents'>
        { generateHighlights(
            [text["highlights-1-title"], text["highlights-2-title"], text["highlights-3-title"]], 
            [text["highlights-1-text"], text["highlights-2-text"], text["highlights-3-text"]], 
            [Flexible, CustomerClose, Trustable]) }
      </div>
    </div>
);

    // Function to generate the highlight components
    function generateHighlights(titles, texts, images) {
        const key = "home-highlights_";
        const html = [];

        for (let i = 0; i < titles.length; i++) {
            html.push( singleHighlight(key + i, images[i], titles[i], texts[i]) );
        }
        return html;
    }

    // Function to generate a single highlight component
    function singleHighlight(key, image, title, text) {

        return (
            <div key={key + "_contents"} className='home-highlight__contents_highlight'>
                <div key={key + "_image_div"} className='home-highlight__contents_highlight-image'>
                    <img key={key + "_image"} src={image} alt='Highlight' />
                </div>
                <h1 key={key + "_title"} className='h3'> {title} </h1>
                <p key={key + "_text"} className='p1'> {text} </p>
            </div>
        );
    }

export default HomeHighlights;