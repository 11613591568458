import React from 'react';
import './landing.css';


/**
 * Brief: The landing page module (the top part of each page)
 * 
 * @param {string} language language to use 
 * @param {html} content All HTML content for this landing page
 * @returns A react module
 */
const Landing = ({ content, image }) => {
    return (
        <div className='landing__background-image bg-pan-br' style={{backgroundImage: `url(${image})`}} id="landing__content-view">
            <div className='landing__background-color'>
                <div className='landing__content'>
                    { content }
                </div>
            </div>
        </div>
    )
};

export default Landing;