import { React } from 'react';
import { NavLink } from 'react-router-dom';
// React components
import scrollIntoView from '../../landing/scrollIntoView';
import Landing from '../../landing/Landing'
import ShowMore from '../../landing/ShowMore';
import News from '../../news/News';
import Offer from './HomeOffer';
import About from './HomeAbout';
import Highlights from './HomeHighlights';

// Styling
import './home.css';
import '../../body/body.css';
// Assets
import LandingBackground from '../../../assets/grav1.jpg'; 
import BGVideo from '../../../assets/video.mp4';

/// Main home view
const Home = ({ language, text, textNews, onViewedPage }) => {
    onViewedPage('/', 'Home');

    /**
     * @brief Contains the landing page with a video background
     * @returns HTML
     */
    function videoLanding() {
        if (window.innerHeight < window.innerWidth && window.innerHeight > 800) {
            return (
                <>
                <div className='landing__video'>
                    <video preload="none" autoPlay loop muted id='video'>
                            <source src={BGVideo} type='video/mp4' />
                    </video>
                </div>
                <Landing content={LandingContent()} image={''} />
                </>
            );
          }
          return (<Landing content={LandingContent()} image={LandingBackground} />);
    }

    // Content for landing page
    const LandingContent = () => (
        <>
            <div className='home__landing'>
                <div className='home__landing-slogan'>
                    <h1 className='glowing'>{text["landing-title-slogan1"]}</h1>
                    <h1>{text["landing-title-slogan2"]}</h1>
                    <h1>{text["landing-title-slogan3"]}</h1>
                    {/* <br />
                    <p>{text["offer-title-slogan-text"]}</p> */}
                </div>
                <p className='p1'> {text["landing-slogan-p"]} </p>
                <div className='home__landing-buttons'>
                    <NavLink className='button button_filled-black' onClick={() => scrollIntoView("main__first")}>
                        {text["button-why-us"].toUpperCase()}
                    </NavLink>
                    <NavLink className='button button_hollow-white' to={"about"}>
                        {text["button-more"].toUpperCase()}
                    </NavLink>
                </div>
            
            </div>
            <ShowMore scrollTo={"main__first"} text={text['home-more']} />
        </>
    );

    return (
        <>
            {videoLanding()}
            <div className='body__content'>
                
                <div className='main__component' id="main__first">
                    <Highlights text={text} />
                </div>

                <div className='main__component'>
                    <Offer text={text} />
                </div>

                <div className='main__component'>
                    <News language={language} text={textNews} />
                </div>
                
                <div className='main__component'>
                    <About text={text} />
                </div>

                {/* Embedded video showcasing company work */}
                <div className='main__component'>
                    <h1> {text["home-see-us-in-work"]} </h1>
                    <br />
                    <div className='main__video'>
                        <iframe 
                            width="100%" 
                            height="100%" 
                            src="https://www.youtube.com/embed/Jj_OpsC8qiw?si=hdD-zZAMf6M1FeXZ" 
                            title="YouTube video player" 
                            allow="accelerometer; 
                            autoplay; 
                            clipboard-write; 
                            encrypted-media; 
                            gyroscope; 
                            picture-in-picture; 
                            web-share" 
                            referrerPolicy="strict-origin-when-cross-origin" 
                            allowFullScreen
                        >
                        </iframe>
                    </div>
                </div>
            </div>
            </>
    )
};

export default Home;