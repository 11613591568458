import { React, useState, useEffect, useRef } from 'react';
import { HashLink } from 'react-router-hash-link';
import { BiChevronRight } from "react-icons/bi";

import OfferService from '../../../assets/arbete.jpg'; 
import OfferContracts from '../../../assets/ritning2.jpg'; 
import OfferLightning from '../../../assets/belysningsprodukter1.jpg'; 
import OfferTechnical from '../../../assets/arbete2.jpg'; 

import './home-offer.css';
import './home.css';

/**
 * This component represents the "offer" buttons on the home page
 */


// Offer images
var offerService = {  backgroundImage: `url(${OfferService})` };
var offerContracts = {  backgroundImage: `url(${OfferContracts})` };
var offerLightning = {  backgroundImage: `url(${OfferLightning})` };
var offerTechnical = {  backgroundImage: `url(${OfferTechnical})` };

// Each offer button in the offer view on the home page
const OfferButton = ({ anchor, image, header, text }) => {
        const [isVisible, setIsVisible] = useState(false);
        const elementRef = useRef(null);
      
        useEffect(() => {
          const current = elementRef.current;

          const observer = new IntersectionObserver(
            ([entry]) => {
              if (entry.isIntersecting) {
                setIsVisible(true);
                observer.unobserve(entry.target); // Stop observing once the element is visible
              }
            },
            {
              threshold: 0.3, // Adjust this value according to when you want the animation to trigger
            }
          );
      
          if (current) {
            observer.observe(current);
          }
      
          return () => {
            if (current) {
              observer.unobserve(current);
            }
          };
        }, []);
    
    return (
        <div ref={elementRef} className={`home__offer-container-item-image ${isVisible ? 'slide-in-blurred-bottom' : 'hidden'}`} style={image}>
                <HashLink to={anchor} className='home__offer-container-item'>
                    <h1> {header} </h1>
                    <div className='home__offer-container-item-arrow heartbeat'>
                      <BiChevronRight color='white' size={48} />
                    </div>
                    <p> {text} </p>
                </HashLink>
        </div> 
    );
}

/// Offer view on home page
const HomeOffer = ({ text }) => {
    return (
    <div className='home__offer'>
        <h1>{text["offer-mini-header"]}</h1>
        <div className='home__offer-container'>
            <OfferButton anchor="/offer#offer__service" image={offerService} header={text["offer-mini-1-header"]} text={text["offer-mini-1-text"]} />
            <OfferButton anchor="/offer#offer__contracts" image={offerContracts} header={text["offer-mini-2-header"]} text={text["offer-mini-2-text"]} />
            <OfferButton anchor="/offer#offer__lights" image={offerLightning} header={text["offer-mini-3-header"]} text={text["offer-mini-3-text"]} />
            <OfferButton anchor="/offer#offer__tech" image={offerTechnical} header={text["offer-mini-4-header"]} text={text["offer-mini-4-text"]} />
        </div>
    </div>
    );
}

export default HomeOffer;
