import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import axios from "axios";
import NewsItem from './NewsItem';

import './news.css';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
// import required swiper modules
import { EffectCoverflow, Pagination } from 'swiper/modules';

/**
 * @brief Get all swiper slides
 * @param {HTML} newsItems 
 * @param {function} onOpen 
 * @param {string} language 
 * @param {JSON} buttonText // Text for all buttons and such
 * @returns Return Swiper Slide components
 */
function getNews(newsItems, onOpen, language, buttonText) {

	let newsComponents = [];

	for (let i = 0; i < newsItems.length; i++) {
		// Create news item
		const item = {
			title: (language === 'sv') ? newsItems[i].title_sv : newsItems[i].title_en,
			text: (language === 'sv') ? newsItems[i].text_sv : newsItems[i].text_en,
			date: newsItems[i].published,
			image: newsItems[i].image,
			id: newsItems[i].id
		};
        
		let image = newsItems[i].image;
		const comp = 
			<SwiperSlide key={'newsitem' + item.id}>
				<NewsItem 
					id={i} key={item.id} 
					title={item.title} 
					date={item.date} 
					textmessage={item.text} 
					image={image} 
					isOpened={false} 
					onOpen={onOpen} 
                    buttonText={buttonText}
				/> 
			</SwiperSlide>
		newsComponents.push(comp);
	}
	return newsComponents;
}

/// Swiper component - creates a pretty slideshow / carousel
const NewsSwiperContainer = ( {language, text} ) => {
    // Fetch the latest news from the server
    const [newsSlides, setNewsSlides] = useState([]);
    const [newsItems, setNewsItems] = useState([]);
    const [chosen, setChosen] = useState(0);
    const [reloadSlides, setReloadSlides] = useState(true);

    // For opened news - modal is the pop-up screen
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = (id) => {
        setChosen(id);
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };

    /**
     * @brief Fetch news data from server
     * @param {int} maxNexs max number of news to fetch from server
     * @param {bool} resetReload Whether to reload the swiper container
     */
    function fetchNewsData(maxNexs, resetReload) {
        let fetchedNews = [];
        
        const backendUrl = process.env.REACT_APP_BACKEND_PHP;
        // First get the news text data
        axios.get(backendUrl + 'NEWS/?n=' + maxNexs)
        .then(async response => {
            // Check whether empty data has been found in database
            if (response.data == undefined || response.data == null)
                throw "Data could not be found!";

            // Take the data
            fetchedNews = response.data;

            // Then get all images
            const promises = [];
            for (let i = 0; i < fetchedNews.length; i++) {
            fetchedNews[i].image = "";
            promises.push(new Promise((resolve, reject) => {
                fetch(backendUrl + 'NEWS/IMAGES/?id=' + fetchedNews[i].id)
                .then(response => response.blob())
                .then(blob => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        fetchedNews[i].image = reader.result;
                        resolve(); // Resolve the promise when the image is read
                    };
                    reader.readAsDataURL(blob);
                })
                .catch(error => reject(error));
            }));
            }
        // Wait for all images to arrive before storing news
            Promise.all(promises) 
            .then(() => {
                setNewsItems(fetchedNews);
                if (resetReload) setReloadSlides(true);
            })
            .catch(error => console.error(error));
        })
        .catch(error => {
            console.log(error);
        });
    }

    // Fetch news from server
    useEffect(() => {
        const numberOfNews = 5;
        fetchNewsData(numberOfNews, false);
    }, [language]);
        
    
    // Set up all news slides
    if (newsItems.length > 0 && reloadSlides) {
        const allNews = getNews(newsItems, showModal, language, text);
        setNewsSlides(allNews);
        setReloadSlides(false);
    }

    return (
        <>
        <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        breakpoints={{
            0: { slidesPerView: 1 }, // Change back to 1 slide when window width is >= 768px
            850: { slidesPerView: 2 }, // Change to 2 slides when window width is >= 640px
            }}
        coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
        }}
        pagination={{clickable: true}}
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper"
        >
            {newsSlides}
            <SwiperSlide>
                <div className='news__see-more'>
                    <Button onClick={() => fetchNewsData(25, true)} size='large'>Se äldre nyheter</Button>
                </div>
            </SwiperSlide>
        </Swiper>
        <Modal title="" open={isModalOpen} onOk={handleOk} onCancel={handleOk} width={(window.innerWidth > 1100 ? 1000 : '')}
            footer={[
                <Button key="back" onClick={handleOk}>
                {(language == 'sv') ? "Tillbaka" : "Back"}
                </Button>
            ]}
        >
            {newsSlides.length > 0 && (
                <div className='news__opened-news'>
                    <NewsItem 
                    language={language}
                    id={chosen} 
                    key={newsItems[chosen].id} 
                    title={(language === 'sv') ? newsItems[chosen].title_sv : newsItems[chosen].title_en} 
                    date={newsItems[chosen].published} 
                    textmessage={(language === 'sv') ? newsItems[chosen].text_sv : newsItems[chosen].text_en} 
                    image={newsItems[chosen].image} 
                    isOpened={true} 
                    onOpen={""} 
                    buttonText={text}
                    /> 
                </div>
            )}
        </Modal>
        </>
    )
}

export default NewsSwiperContainer;