import React from 'react';
import { BiChevronRight } from "react-icons/bi";

import './news-item.css';

/**
 * @brief Shrink title and text if it is a slide in the slide show
 * @param {string} textmessage 
 * @param {string} title 
 * @returns JSON
 */
function getPeekData(textmessage, title) {
    const MAX_CHARACTERS_SHORT = 128;
	const MAX_CHARACTERS_LONG = 188;
    const MAX_CHARACTERS_TITLE = 26;

    const textLimitationShort = textmessage.slice(0, MAX_CHARACTERS_SHORT) + "..."; // Limit preview text of news
    const textLimitationLong = textmessage.slice(0, MAX_CHARACTERS_LONG) + "..."; // Limit preview text of news
    const addDots = (title.length > MAX_CHARACTERS_TITLE) ? '..' : '';
    const titleLimitationShort = title.slice(0, MAX_CHARACTERS_TITLE) + addDots; // Limit preview title of news

    return {textShort: textLimitationShort, textLong: textLimitationLong, title: titleLimitationShort};
}

function getMultiLineText(text, id) {
    const html = [];
    
    const lines = text.split("\n");
    for (let i = 0; i < lines.length; i++) {
        const e = lines[i];
        if (e !== undefined && e !== "")
            html.push(<p key={id + "_text_" + i} className='news__item-full-text'> {e} </p>);
    }

    return html;
}

/**
 * @brief Represents the view on one news item
 * @param {string} id 
 * @param {string} date 
 * @param {string} title 
 * @param {string} textmessage 
 * @param {BLOB} image 
 * @param {boolean} isOpened
 * @param {function} onOpen
 * @param {JSON} buttonText - text for buttons, such as OK etc
 * @returns HTML
 */
const NewsItem = ({ id, date, title, textmessage, image, isOpened, onOpen, buttonText }) => {
    // Get formated text
    const peekData = getPeekData(textmessage, title);
    const titleShort = (isOpened) ? title : peekData.title;
    const titleLong = title;
    const textShort = (isOpened) ? textmessage : peekData.textShort;
    const textLong = (isOpened) ? textmessage : peekData.textLong;

    const fullText = getMultiLineText(textmessage, id);
    
    return (
    <div className='news__item'>
        <img src={image} alt='news article' />
        <div className='news__items-data'>
            <h1 className='news__mobile'> {titleShort} </h1>
            <h1 className='news__desktop'> {titleLong} </h1>
            <p id="news__item-date"> {buttonText["published"] + date} </p>
            <hr />
            {!isOpened && (
                <>
                <p id="news__item-text-short"> {textShort} </p>
                <p id="news__item-text-long"> {textLong} </p>
                </>
            )}
            {isOpened && (fullText)}
        </div>
        {!isOpened && (
            <div className='news__item-morenews'>
                <span onClick={() => onOpen(id)}> {buttonText["read-more"]} </span>
                <BiChevronRight size={32} color="#313486" />
            </div>
        )}
    </div>
    );
}
    

export default NewsItem;