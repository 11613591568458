import { React } from 'react';
import api from '../api';
import CRUDadd from '../CRUDadd';
import { Form, Input, DatePicker } from "antd";
import dayjs from 'dayjs';

const { TextArea } = Input;

const AdminNewsPut = ({ isModalOpen, closeModal, onGetCurrentNews, onLogout }) => {

    const currentValue = onGetCurrentNews();
    
    async function submitData(values) {

        // Set date to MySQL-DATE format
        const dateYear = values.published["$y"];
        const dateMonth = values.published["$M"]+1;
        const dateDay = values.published["$D"];
        const date = `${dateYear}-${dateMonth}-${dateDay}`;

        values.id = currentValue.id;
        values.published = date;
        const newsitem = JSON.stringify(values);
    
        api.put('NEWS/', newsitem)
          .then(response => {
            window.location.reload();
          })
          .catch(error => {
            console.error(error);
            onLogout();
          });
    } 

    const dateFormat = 'YYYY-MM-DD';

    const items = (
        <>
        <Form.Item name="title_sv" label="Titel (svenska)" initialValue={currentValue.title_sv}
            rules={[{pattern: new RegExp(/^[ ()/\-!?:',.éÉüÜåäöÅÄÖa-zA-Z0-9]*$/),message: "Inga skumma symboler tack!"},
                {required: true,message: "Detta fält behövs!",},]}>
            <Input />
        </Form.Item>
        <Form.Item name="title_en" label="Titel (engelska)" initialValue={currentValue.title_en}
            rules={[{pattern: new RegExp(/^[ ()/\-!?:',.éÉüÜåäöÅÄÖa-zA-Z0-9]*$/),message: "Inga skumma symboler tack!"},
                {required: true,message: "Detta fält behövs!",},]}>
            <Input />
        </Form.Item>
        <Form.Item name="text_sv" label="Text (svenska)" initialValue={currentValue.text_sv}
            rules={[{pattern: new RegExp(/^[ ()/\-!?':,.éÉüÜåäöÅÄÖa-zA-Z0-9\n]*$/),message: "Inga skumma symboler tack!"},
                {required: true,message: "Detta fält behövs!",},]}>
            <TextArea rows={4} />
        </Form.Item>
        <Form.Item name="text_en" label="Text (engelska)" initialValue={currentValue.text_en}
            rules={[{pattern: new RegExp(/^[ ()/\-!?':,.éÉüÜåäöÅÄÖa-zA-Z0-9\n]*$/),message: "Inga skumma symboler tack!"},
                {required: true,message: "Detta fält behövs!",},]}>
            <TextArea rows={4} />
        </Form.Item>
        <Form.Item 
            initialValue={dayjs(currentValue.published, dateFormat)}
            label="Publiceringsdatum:" 
            name="published"
            rules={[{ required: true, message: 'Vänligen välj publiceringsdatum' }]}
        >
            <DatePicker />
        </Form.Item>
        </>
    )

    return (<CRUDadd isModalOpen={isModalOpen} closeModal={closeModal} handleSubmitData={submitData} title={"Redigera en nyhet"} items={items} />);
};

export default AdminNewsPut;