import React from 'react';
import './news.css';
import NewsSwiperContainer from './NewsSwiperContainer'

/// News component
const News = ({ language, text }) => {
    // Get all new slides
    return (
        <div className='news__view'>
            <h1>{text["news-header"]}</h1>
            <div className='news__container'>
                <NewsSwiperContainer language={language} text={text} />
            </div>
        </div>
    )
}

export default News;
