import React from 'react';
import './contactMap.css';

const ContactMap = ({ text, url, city, street, zip }) => {
  
    return (
        <>
        <div className='contact__map'>
            <div className='contact__map-frame'>
                <iframe
                title={city + "_office"}
                src={url}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                />
            </div>
            <div className='contact__people contact__map-text'>
                <h1>{city + text["contact-office"]}</h1>
                <h2 className='h4'>{text["contact-visit-address"]}:</h2>
                <p>{street}</p>
                <p>{zip}</p>
            </div>
        </div>
        </>
    );
}

export default ContactMap;