import { React, useState } from 'react';
import { Button, Modal, Form, Alert, Spin } from "antd";

/**
 * CRUDadd is used for EVERY ADD and PUT component. Those components are inserted in the form of this component!
 */

const CRUDadd = ({ isModalOpen, closeModal, handleSubmitData, title, items }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const handleOk = () => {
        form.validateFields().then(values => {
            setLoading(true);
            handleSubmitData(values).finally(() => {
                setLoading(false);
                form.resetFields();
                closeModal();
            });
        }).catch(info => {
            // form validation failed, do nothing
            console.log('Validation failed:', info);
        });
    };

    const handleCancel = () => {
        form.resetFields();
        closeModal();
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e?.fileList;
    };

    // Check if there are any form errors
    const hasErrors = Object.values(form.getFieldsError()).some(({ errors }) => errors.length > 0);

    return (
        <Modal 
            title={title}
            open={isModalOpen}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                  Avbryt
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={handleOk} disabled={loading || hasErrors}>
                  Slutför
                </Button>,
            ]}
        >
            <Spin spinning={loading}>
                <Form
                    form={form}
                    labelCol={{ span: 7 }}
                    wrapperCol={{ span: 16 }}
                    layout="horizontal"
                    style={{ maxWidth: 800 }}
                >
                    {hasErrors && (
                        <Form.Item wrapperCol={{ span: 24 }}>
                            <Alert 
                                message="Fyll i alla fält korrekt!" 
                                type="error" 
                                showIcon 
                            />
                        </Form.Item>
                    )}
                    {items}
                </Form>
            </Spin>
        </Modal>
    );
};

export default CRUDadd;