import React from 'react';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
// React components
import Sidebar from '../sidebar/Sidebar';
// Styling
import './header.css';
import './languageBar.css';
// Assets
// TODO: There is code for replacing the logo text with a logo image, however the current image looked bad when scaled down
// import Logo from '../../assets/Logo.png'; 
import { BiChevronDown, BiMenu, BiX } from "react-icons/bi";

/// States for the language bar and the side bar
const BarStates = Object.freeze({
  DEACTIVATED: 0, // non-visable
  DEACTIVATED_ANIM: 1, // moving inwards and becoming non-visable
  ACTIVATED: 2, // visable
  ACTIVATED_ANIM: 3 // moving outwards and becoming visable
});

/// Toggle the language bar depending on the state (BarStates)
function toggleLanguageBar(state) {
  let languageButtonClass;
  if (state === BarStates.ACTIVATED) {
    languageButtonClass = 'header__language-bar';
  }
  if (state === BarStates.ACTIVATED_ANIM) {
    languageButtonClass = 'header__language-bar swing-in-top-fwd';
  }
  if (state === BarStates.DEACTIVATED) {
    languageButtonClass = 'header__language-bar-off';
  }
  if (state === BarStates.DEACTIVATED_ANIM) {
    languageButtonClass = 'header__language-bar swing-out-top-bck';
  }

  return languageButtonClass;
}

/// The header / menu-bar
const Header = ({ text, sidebarText, changeLanguage, language }) => {
    // States
    const [languageBar, setlanguageBar] = useState(BarStates.DEACTIVATED);
    const [sideBar, setSideBar] = useState(BarStates.DEACTIVATED);
    const [fillMenubar, setFillMenubar] = useState(window.scrollY > 32  ? true : false);
    const location = useLocation();

    let languageButtonClass = toggleLanguageBar(languageBar);

    // Methods
    const onLangSelectionButton = () => {
      if (languageBar === BarStates.DEACTIVATED || languageBar === BarStates.DEACTIVATED_ANIM) {
        setlanguageBar(BarStates.ACTIVATED_ANIM);
        setSideBar(BarStates.DEACTIVATED);
      } 
      else {
        setlanguageBar(BarStates.DEACTIVATED_ANIM);
      }
    };

    const onSidebarButton = () => {
      if (sideBar === BarStates.DEACTIVATED || sideBar === BarStates.DEACTIVATED_ANIM) {
        setSideBar(BarStates.ACTIVATED_ANIM);
        setlanguageBar(BarStates.DEACTIVATED);
      } 
      else {
        setSideBar(BarStates.DEACTIVATED_ANIM);
      }
    };

    /// When choosing another language, change language and roll back language bar
    const onChangeLanguage = (lang) => {
      changeLanguage(lang);
      setlanguageBar(BarStates.DEACTIVATED_ANIM);
    };

    const LangSelectionBar = () => (
      <div className={fillMenubar ? languageButtonClass + " header__language-bar-solid" : languageButtonClass + " header__language-bar-transparent"}>
          <span onClick={() => onChangeLanguage("sv")} > Svenska </span>
          <span onClick={() => onChangeLanguage("en")} > English </span>
      </div>
  );


  useEffect(() => {
    const handleScroll = () => {

      // Make sure scrolling event doesn't screw up animations
      if (languageBar === BarStates.DEACTIVATED_ANIM) {
        setlanguageBar(BarStates.DEACTIVATED);
      }
      if (languageBar === BarStates.ACTIVATED_ANIM) {
        setlanguageBar(BarStates.ACTIVATED);
      }

      if (sideBar === BarStates.DEACTIVATED_ANIM) {
        setSideBar(BarStates.DEACTIVATED);
      }
      if (sideBar === BarStates.ACTIVATED_ANIM) {
        setSideBar(BarStates.ACTIVATED);
      }
      
      if (window.scrollY > 32) {
        setFillMenubar(true); 
      } else {
        setFillMenubar(false);
      }

    };
    
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [languageBar, sideBar]);

  // Render
  return (
      <>
      <div className='header__bar'>
        <div className={fillMenubar ? 'header__bar-fill fade-in' : 'header__bar-fill fade-out'}>
          <div className={'header__content'}>
              <HashLink smooth={(location.pathname === '/' ? true : false)} to="/#" className='header__content-logo'>
                  <h1 id='header__content-logo-bogfelts'>BOGFELTS</h1>
                  <h1 id='header__content-logo-sl'>STREET LIGHTS</h1>
                  {/* <img src={Logo} alt='Logo' /> */}
              </HashLink>

              <div className='header__content-menu'>
                  <HashLink smooth={(location.pathname === '/offer' ? true : false)} className={(location.pathname === '/offer' ? 'link__selected' : '')}
                    to="/offer#">{text["menu-offer"]}</HashLink>
                  <HashLink smooth={(location.pathname === '/about' ? true : false)} className={(location.pathname === '/about' ? 'link__selected' : '')}
                    to="/about#">{text["menu-aboutus"]}</HashLink>
                  <HashLink smooth={(location.pathname === '/work' ? true : false)} className={(location.pathname === '/work' ? 'link__selected' : '')}
                    to="/work#">{text["menu-jobs"]}</HashLink>
                  <HashLink smooth={(location.pathname === '/contact' ? true : false)} className={(location.pathname === '/contact' ? 'link__selected' : '')}
                    to="/contact#">{text["menu-contact"]}</HashLink>
              </div>
              <div className='header__content-language-button' onClick={onLangSelectionButton}>
                  <p>{text["language-button"]}</p>
                  <BiChevronDown color='white' size={28} />
              </div> 
              <div className='header__sidebar-button' onClick={onSidebarButton}>
                {(sideBar === BarStates.DEACTIVATED || sideBar === BarStates.DEACTIVATED_ANIM) 
                  && (<BiMenu color='white' size={40} />)}
                {(sideBar === BarStates.ACTIVATED || sideBar === BarStates.ACTIVATED_ANIM) 
                  && (<BiX color='white' size={40} />)}
                
              </div>
          </div>
        </div>
        <LangSelectionBar />
        <Sidebar text={sidebarText} onSetSidebar={onSidebarButton} sidebarStatus={sideBar} />
        </div>
      </>
    )
}

export default Header;