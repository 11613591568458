import React from 'react';
import { BsLinkedin, BsFacebook, BsInstagram, BsFileEarmarkPdf } from "react-icons/bs";
import { HashLink } from 'react-router-hash-link';

import './footer.css';

import FooterBackground from '../../assets/footer.jpg'; 
import FooterBrochure from '../../assets/Bogfelts_broschyr_2024.pdf'; 
import AAA from '../../assets/AAABogfelts.png'; 

/// All text on the page is stored in json files

var background = {  
    backgroundImage: `url(${FooterBackground})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
};

const Footer = ({ text }) => {
    return (
        <div className='footer__background' style={background}>
            <div className='footer__overlay'>
                <div className='footer__content'>
                    <div className='footer__content-logo'>
                        <h1>Bogfelts Street Lights AB</h1>
                        <img src={AAA} alt="aaa certifikat" />
                    </div>
                    <hr />
                    <div className='footer__content-support'>
                        <h1>Kontakta oss</h1>
                        <HashLink smooth to="/contact#contact__form">{text["footer-fault"]}</HashLink>
                        <HashLink smooth to="/contact#">{text["footer-contact"]}</HashLink>
                        <HashLink smooth to="/policy/#">{text["footer-cookies"]}</HashLink>
                    </div>
                    <hr />
                    <div className='footer__content-stockholm'>
                        <h1>{text["footer-visit"]} Stockholm</h1>
                        <p>Klockar malms väg 23</p>
                        <p>182 34 Danderyd</p>
                        <p>Stockholms län</p>
                    </div>
                    <hr />
                    <div className='footer__content-orebro'>
                        <h1>{text["footer-visit"]} Örebro</h1>
                        <p>Adolfbergsvägen 2-4</p>
                        <p>702 27 Örebro</p>
                        <p>Örebros län</p>
                    </div>
                    <hr />
                    <div className='footer__content-media'>
                        <h1> Media </h1>
                        <div className='footer__content-socialmedia'>
                            {/* X search results: <a href='https://x.com/search?q=bogfelts&src=typd'> <BsTwitterX color='white' size={24} /> </a> */}
                            <a href='https://www.linkedin.com/company/bogfelts'> <BsLinkedin color='white' size={24} /> </a> 
                            <a href='https://www.facebook.com/bogfelts'> <BsFacebook color='white' size={24} /> </a>  
                            <a href='https://www.instagram.com/bogfelts/'> <BsInstagram color='white' size={24} /> </a>
                        </div>
                        <a href={FooterBrochure} target='_blank' rel='noopener noreferrer' className='footer__content-pdf'>
                            <BsFileEarmarkPdf color='white' size={24} />
                            <span>{text["footer-pdf"]}</span>
                        </a>
                    </div>
                </div>

                <div className='footer__content-copyright'>
                    <p>©2024 Bogfelts Street Lights AB</p>
                </div>
            </div>
        </div>
    )
}

export default Footer;