import React from 'react';
import { createRoot } from 'react-dom/client'; 
import { HashRouter as Router } from 'react-router-dom';

import App from './App.js';
import './index.css';

// Replaced "ReactDom.render(..)"
const root = createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Router>
            <App />
        </Router>
    </React.StrictMode>
);


