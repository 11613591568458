import { React } from 'react';
import api from '../api';
import { Form, Input, Button, Checkbox, Tooltip } from "antd";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import CRUDadd from '../CRUDadd';
const { TextArea } = Input;

const AdminWorkAdd = ({ isModalOpen, closeModal, onLogout }) => {
    async function submitData(values) {
        // Serialize arrays to strings
        values.locations = values.locations.join();
        values.requirements = values.requirements.join();
        values.merits = values.merits.join();
        const job = JSON.stringify(values);

        api.post('WORK/', job)
        .then(response => {
            window.location.reload();
        })
        .catch(error => {
            console.error(error);
            onLogout();
        });
    }
    const formItemLayout = {
        labelCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 4,
          },
        },
        wrapperCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 24,
          },
        },
    };
    const formItemLayoutWithOutLabel = {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 24,
            offset: 4,
          },
        },
    };

    /**
     * @brief A list where you can add or remove items
     * @param {string} name 
     * @param {string} title 
     * @param {string} addText 
     * @param {string} placeholder 
     * @param {string} messageText 
     * @returns 
     */
    const addableList = (name, title, addText, placeholder, messageText, promiseMessage) => (
      <Form.List
        name={name}
        rules={[
          {
            validator: (_, value) => 
              value && value.length > 0 ? Promise.resolve() : 
              Promise.reject(promiseMessage), // Use the promise message here
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                key={field.key}
                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                label={index === 0 ? title : ''}
                required={true}
              >
                <Form.Item
                  name={field.name}
                  validateTrigger={['onChange', 'onBlur']}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: messageText,
                    },
                  ]}
                  noStyle
                >
                  <Input
                    placeholder={placeholder}
                    style={{ width: '60%' }}
                  />
                </Form.Item>
                {fields.length > 1 ? (
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                  />
                ) : null}
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                style={{ width: '60%' }}
                icon={<PlusOutlined />}
              >
                {addText}
              </Button>
              {errors && errors.length > 0 && <Form.ErrorList errors={errors} />}
            </Form.Item>
          </>
        )}
      </Form.List>
    );

    const items = (
        <>
        <Form.Item name="title" label="Titel" 
            rules={[{pattern: new RegExp(/^[ ()\-!?':,.üÜåäöÅÄÖa-zA-Z0-9]*$/),message: "Inga skumma symboler tack!"},
                {required: true,message: "Detta fält behövs!",},]}>
            <Input />
        </Form.Item>
        {addableList("locations", "Ort", "Lägg till ort", "ortsnamn", "Ange eller ta bort ort!", "Minst en ort!")}
        {addableList("requirements", "Krav", "Lägg till krav", "kravbeskrivning", "Ange eller ta bort krav!", "Minst ett krav!")}
        {addableList("merits", "Meriterande", "Lägg till merit", "meritbeskrivning", "Ange eller ta bort merit!", "Minst en merit!")}
        <Form.Item name="info" label="Beskrivning"
            rules={[{pattern: new RegExp("^[ %()/\\-!?':,.éÉåäöÅÄÖa-zA-Z0-9\\n]*$"),message: "Inga skumma symboler tack!"},
                {required: true,message: "Detta fält behövs!",},]}>
            <TextArea rows={4} />
        </Form.Item>
        <Tooltip placement="bottom" title={"Huruvida denna jobbannons endast skall dyka upp om sidan är på svenska!"}>
          <Form.Item name="swedish_only" valuePropName="checked" initialValue={true}>
              <Checkbox>Endast på svenska</Checkbox>
          </Form.Item>
        </Tooltip>
        
        </>
    );

    return (<CRUDadd isModalOpen={isModalOpen} closeModal={closeModal} handleSubmitData={submitData} title={"Lägg till ny ledig tjänst"} items={items} />);
};

export default AdminWorkAdd;