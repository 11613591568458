import React from 'react';
import { HashLink } from 'react-router-hash-link';
// Styling
import './workEntry.css';
// Assets
import { BiUser } from "react-icons/bi";

// Creates a job entry field - typ det innuti ort, krav osv
function createFields(data, key) {
    const fields = [];
    
    for (let i = 0; i < data.length; i++) {
        const field = <p key={key + '_' + data[i]} className='work__availablework-entry-field'>{data[i]}</p>;
        fields.push(field); 
    }

    return fields;
}

// Creates one job in the available job items
const WorkEntry = ({ text, data, onSetJob }) => {
    const key = 'work_entry_' + data.title;
    const locations = data.locations.split(",");
    const requirements = data.requirements.split(",");
    const merits = data.merits.split(",");

    return (
        <div className='work__availablework-entry' key={key + '_div'}>
            <div className='work__availablework-entry-title'>
                <div className='work__availablework-entry-icon'>
                    <BiUser size="24"/>
                </div>
                <h1 className='h3' key={key + '_h1'}>{data.title}</h1>
            </div>
            <div className='work-availablework-entry-content'>
                <div className='work-availablework-entry-col'>
                    <p key={key + '_ort'} className='work__availablework-entry-label'>{text['jobs_entry-location']}</p>
                    {createFields(locations, key)}
                    <p key={key + '_krav'} className='work__availablework-entry-label'>{text['jobs_entry-requirements']}</p>
                    {createFields(requirements, key)}
                    <p key={key + '_merit'} className='work__availablework-entry-label'>{text['jobs_entry-assets']}</p>
                    {createFields(merits, key)}
                </div>
                <div className='work-availablework-entry-col'>
                    <p key={key + '_info'} className='work__availablework-entry-label'>INFO</p>
                    <p key={key + '_info_data'} style={{whiteSpace: 'pre-line'}} className='work__availablework-entry-field'>{data.info}</p>
                </div>
            </div>
            <div key={key + '_apply_area'} className='work__availablework__submit'>
                <HashLink className='button-7' key={key + '_apply'} smooth onClick={() => onSetJob(data.title)} to={"/work#"}>Ansök</HashLink>
            </div>
        </div>
    );
}

export default WorkEntry;